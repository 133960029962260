import { Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { IFilterForm } from '../types/filterFormTypes';
import { SelectOptions } from 'shared/types/invoices';
import { CustomLabel } from 'shared/components/customLabel';
import { dateRangeOptions } from '../config/dateRangeOptions';
import { useGetCompaniesQuery } from 'features/invoices/api';
import { isEmpty, isEqual } from 'lodash';
import { Button } from 'shared/components/button';
import '../styles/filterForm.scss';
import { statusesOptions } from '../config/statusesOptions';
import { ICompaniesResponse } from 'features/invoices/types/invoicesTypes';
interface IFiltersForm {
  dataCreatedInit: IFilterForm['dateCreated'];
  companiesInit: string[];
  handleFiltersApply: (values: IFilterForm) => void;
  handleClearAll: () => void;
  filters: string[];
  statusFilter?: string | null;
  processedFilter?: IFilterForm['processed'];
}

export const FiltersForm: React.FC<IFiltersForm> = ({
  dataCreatedInit,
  companiesInit,
  handleFiltersApply,
  handleClearAll,
  filters,
  statusFilter,
  processedFilter,
}) => {
  const [isDisabledApply, setIsDisabledApply] = useState(true);
  const [companies, setCompanies] = useState<ICompaniesResponse['results']>([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    isFetching: isCompaniesFetching,
    isSuccess,
    refetch: refetchCompanies,
  } = useGetCompaniesQuery({
    page,
    size: 20,
  });

  useEffect(() => {
    if (companiesData?.results.length) {
      setCompanies((prev) => [...prev, ...companiesData.results]);
    }
    if (companiesData?.next) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [companiesData]);

  useEffect(() => {
    refetchCompanies();
  }, []);

  const companiesOptions = useMemo((): SelectOptions[] => {
    if (!companies.length) {
      return [];
    }
    return companies.map((company) => ({
      value: `${company.id}`,
      label: company.name,
    }));
  }, [companies]);

  const [form] = useForm<IFilterForm>();

  const initialState: IFilterForm = useMemo(() => {
    const fullState = {
      companies: companiesInit || [],
      dateCreated: dataCreatedInit || null,
      processed: processedFilter || null,
      status: statusFilter || null,
    };
    const filteredState = Object.keys(fullState)
      .filter((key) => filters.includes(key as keyof IFilterForm))
      .reduce((obj, key) => {
        const typedKey = key as keyof IFilterForm;
        obj[typedKey] = fullState[typedKey] as any;
        return obj;
      }, {} as IFilterForm);
    return filteredState;
  }, [
    companiesInit,
    dataCreatedInit,
    statusFilter,
    processedFilter,
    isSuccess,
    filters,
  ]);
  useEffect(() => {
    form.setFieldsValue(initialState);
  }, [initialState, form]);

  const handleSubmit = (values: IFilterForm) => {
    handleFiltersApply(values);
    setIsDisabledApply(true);
  };

  const handleFormValuesChange = () => {
    const values = form.getFieldsValue();

    if (isEqual(values, initialState)) {
      setIsDisabledApply(true);
      return;
    }
    const hasNonEmptyField = Object.keys(values).some((key) => {
      const value = values[key as keyof IFilterForm];

      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === 'string') {
        return value.trim() !== '';
      } else if (value !== null && typeof value === 'object') {
        return !isEmpty(value);
      } else {
        return value !== null && value !== undefined;
      }
    });
    if (initialState.companies.length && !hasNonEmptyField) {
      handleClearAll();
    }
    setIsDisabledApply(!hasNonEmptyField);
  };

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { target } = event;
    if (
      (target as HTMLDivElement).scrollTop +
        (target as HTMLDivElement).clientHeight >=
      (target as HTMLDivElement).scrollHeight
    ) {
      if (hasMore && setPage) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const formItem = {
    companies: (
      <Form.Item
        label={<CustomLabel text="Companies" />}
        name="companies"
        key={`form-item-companies`}
        rules={[]}
        className={`form-item-companies ${filters.length > 3 ? 'form-item-companies-lg' : ''}`}
      >
        <Select
          mode="multiple"
          placeholder="Select Companies"
          style={{ width: '100%' }}
          options={companiesOptions}
          optionFilterProp="label"
          notFoundContent="No matches found"
          onPopupScroll={handlePopupScroll}
          loading={isCompaniesLoading || isCompaniesFetching}
        ></Select>
      </Form.Item>
    ),
    dateCreated: (
      <Form.Item
        label={<CustomLabel text="Date Created" />}
        key={`form-item-date-created`}
        name="dateCreated"
        rules={[]}
        className={`${filters.length < 3 ? 'form-item-sm' : 'form-item'} form-item-date-created`}
      >
        <Select
          placeholder="Select Range"
          style={{ width: '100%' }}
          options={dateRangeOptions}
        ></Select>
      </Form.Item>
    ),
    status: (
      <Form.Item
        label={<CustomLabel text="Status" />}
        key={`form-item-status`}
        name="status"
        rules={[]}
        className={`${filters.length < 3 ? 'form-item-sm' : 'form-item'}`}
      >
        <Select
          placeholder="Select Status"
          style={{ width: '100%' }}
          options={statusesOptions}
        ></Select>
      </Form.Item>
    ),
    processed: (
      <Form.Item
        label={<CustomLabel text="Date Processed" />}
        key={`form-item-date-processed`}
        name="processed"
        rules={[]}
        className={`${filters.length < 3 ? 'form-item-sm' : 'form-item'}`}
      >
        <Select
          placeholder="Select Range"
          style={{ width: '100%' }}
          options={dateRangeOptions}
        ></Select>
      </Form.Item>
    ),
  };
  return (
    <Form
      form={form}
      layout="vertical"
      style={{
        width: '100%',
      }}
      initialValues={initialState}
      onFinish={handleSubmit}
      onValuesChange={handleFormValuesChange}
      // disabled={isCompaniesLoading || isCompaniesFetching}
    >
      <div
        className={`form-filters-items ${filters.length > 2 ? 'align-end' : 'align-start'}`}
      >
        {filters.length > 2 && (
          <>
            <div className="form-items-with-buttons-lg">
              {formItem['companies']}
              <div className="form-filters-buttons">
                <Button type="button" onClick={handleClearAll}>
                  Clear All
                </Button>

                <Button type="submit" disabled={isDisabledApply}>
                  Apply
                </Button>
              </div>
            </div>
            {filters.map(
              (filtersKey) =>
                filtersKey !== 'companies' &&
                formItem[filtersKey as keyof typeof formItem]
            )}
          </>
        )}
        {filters.length <= 2 && (
          <>
            {formItem['companies']}
            <div className="form-items-with-buttons">
              {formItem['dateCreated']}
              <div className="form-filters-buttons">
                <Button type="button" onClick={handleClearAll}>
                  Clear All
                </Button>

                <Button type="submit" disabled={isDisabledApply}>
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};
