import { Alert, Typography } from 'antd';
import { useVerifyEmailMutation } from 'features/auth/api';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Logo } from 'shared/assets/icons';
import { Button } from 'shared/components/button';
import { ErrorVerify } from 'shared/components/errorVerify';
import { Loading } from 'shared/components/loading';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_EMAIL } from 'shared/constants';
import { setCookie } from 'shared/libs/storageService/cookie';
import { removeLocalStorage } from 'shared/libs/storageService/localStorage';

const Successfully = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [successfullyToken, setSuccessfullyToken] = useState('');
  const [fetchVerifyEmail, { data, isError, isSuccess, isLoading }] =
    useVerifyEmailMutation();

  const navigateToApp = () => {
    navigate(RoutePath['/profile-create']);
  };

  const verifyEmail = async (token: string) => {
    try {
      await fetchVerifyEmail({ token: token }).unwrap();
      removeLocalStorage(USER_EMAIL);
    } catch (e) {
      console.error('verifyEmail', e);
    } finally {
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    const tokenFromUrl = searchParams.get('token');

    if (tokenFromUrl) {
      setSuccessfullyToken(tokenFromUrl);
      verifyEmail(tokenFromUrl);
    }
  }, [searchParams]);

  useEffect(() => {
    data?.access && setCookie(ACCESS_TOKEN, data.access, { secure: true });
    data?.refresh && setCookie(REFRESH_TOKEN, data.refresh, { secure: true });
  }, [data]);

  const isEmptyToken = useMemo(() => {
    return !isLoading && !isLoadingPage && !isError && !successfullyToken;
  }, [isLoading, isLoadingPage, isError, successfullyToken]);

  const isErrorToken = useMemo(() => {
    return !isLoading && !isLoadingPage && (isError || isEmptyToken);
  }, [isLoading, isLoadingPage, isError, isEmptyToken]);

  const isRenderPage = useMemo(() => {
    return !isLoadingPage && !isLoading && !isError && !isEmptyToken;
  }, [isLoadingPage, isLoading, isError, isEmptyToken]);

  return (
    <div className="center-page flex-column">
      {(isLoading || isLoadingPage) && <Loading className="center-page" />}
      {!isLoadingPage && !isErrorToken && (
        <div className="logo-register">
          <Logo />
        </div>
      )}
      {isErrorToken && <ErrorVerify text="The link isn't valid anymore" />}

      {isRenderPage && (
        <>
          <Typography className="extrabold-24 text-center">
            Account Confirmed!
          </Typography>

          <Typography className="normal-16 text-bold mt-16">
            Your email was successfully verified.
          </Typography>

          <Button
            className="mt-16 flex-center flex-row"
            onClick={navigateToApp}
            disabled={!isSuccess || isError}
          >
            Complete Registration
          </Button>
        </>
      )}
    </div>
  );
};

export default Successfully;
