const minLength = 2;
const maxLength = 512;
const minAddreessLength = 1;

export const formFieldsUserName = [
  [
    {
      label: 'Name(s)',
      name: 'name',
      rules: [
        { required: true, message: 'Please input your  Name(s)!' },
        {
          min: minLength,
          max: maxLength,
          message: `Name must be between ${minLength} and ${maxLength} characters.`,
        },
      ],
      validateTrigger: 'onSubmit',
      formItemClassName: 'flex-05',
      inputType: 'text',
      maxLength: maxLength,
      className: 'text-uppercase',
    },
    {
      label: 'First Surname',
      name: 'firstSurname',
      rules: [
        { required: true, message: 'Please input your First Surname!' },
        {
          min: minLength,
          max: maxLength,
          message: `First Surname must be between ${minLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
      className: 'text-uppercase',
    },
  ],
  [
    {
      label: 'Second Last Name (optional)',
      name: 'secondLastName',
      rules: [],
      formItemClassName: 'flex-06',
      className: 'text-uppercase',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },

    {
      label: 'Date of Birth',
      name: 'birthDate',
      rules: [{ required: true, message: 'Please input your Date of Birth!' }],
      formItemClassName: 'flex-04',

      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
  ],
];
// legalEntityName?: string;

export const fieldsAddress = [
  [
    {
      label: 'Type of Road',
      name: ['address', 'roadType'],
      rules: [
        { required: true, message: 'Please input your Type of Road!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Type of Road must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
    {
      label: 'Road Name',
      name: ['address', 'roadName'],
      rules: [
        { required: true, message: 'Please input your Road Name!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Road Name must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
  ],

  [
    {
      label: 'Name of the Locality (optional)',
      name: ['address', 'localityName'],
      rules: [],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },

    {
      label: 'Name of the Federal Entity',
      name: ['address', 'federalEntityName'],
      rules: [
        {
          required: true,
          message: 'Please input your Name of the Federal Entity!',
        },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Name of the Federal Entity must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
  ],

  [
    {
      label: 'Exterior Number',
      name: ['address', 'exteriorNumber'],
      rules: [
        {
          required: true,
          message: 'Please input your Exterior Number!',
        },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Exterior Number must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',
      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },

    {
      label: 'Interior Number (optional)',
      name: ['address', 'interiorNumber'],
      rules: [],
      formItemClassName: 'flex-05',

      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
  ],
  [
    {
      label: 'And Street',
      name: ['address', 'andStreet'],
      rules: [
        { required: true, message: 'Please input your And Street!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `And Street must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      validateTrigger: 'onSubmit',
      formItemClassName: 'flex-05',

      inputType: 'text',
      maxLength: maxLength,
    },
    {
      label: 'Between Street',
      name: ['address', 'betweenStreet'],
      rules: [
        { required: true, message: 'Please input your Between Street!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Between Street must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',

      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
  ],
  [
    {
      label: 'Postal Code',
      name: ['address', 'postalCode'],
      rules: [
        { required: true, message: 'Please input your Postal Code!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Postal Code must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',

      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },

    {
      label: 'State',
      name: ['address', 'state'],
      rules: [
        { required: true, message: 'Please input your State!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `State must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      validateTrigger: 'onSubmit',
      inputType: 'text',
      formItemClassName: 'flex-05',

      maxLength: maxLength,
    },
  ],

  [
    {
      label: 'Name of the Colony',
      name: ['address', 'colonyName'],
      rules: [
        { required: true, message: 'Please input your Name of the Colony!' },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Name of the Colony must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      formItemClassName: 'flex-05',

      validateTrigger: 'onSubmit',
      inputType: 'text',
      maxLength: maxLength,
    },
    {
      label: 'Name of the Municipality or Territorial Demarcation',
      name: ['address', 'municipalityName'],
      rules: [
        {
          required: true,
          message:
            'Please input your Name of the Municipality or Territorial Demarcation!',
        },
        {
          min: minAddreessLength,
          max: maxLength,
          message: `Name of the Municipality or Territorial Demarcation must be between ${minAddreessLength} and ${maxLength} characters.`,
        },
      ],
      validateTrigger: 'onSubmit',
      inputType: 'text',
      formItemClassName: 'flex-05',

      maxLength: maxLength,
    },
  ],
];
