import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'shared/assets/icons';
import { Button } from 'shared/components/button';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';

const ResetPasswordComplete = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(RoutePath['/login']);
  };

  return (
    <div className="center-page flex-column">
      <div className="logo-register">
        <Logo />
      </div>

      <>
        <Typography className="extrabold-24 text-center">
          New password was applied.
        </Typography>

        <Typography className="normal-16 text-bold mt-16">
          Log in with new password
        </Typography>

        <Button
          className="mt-16 flex-center flex-row"
          onClick={navigateToLogin}
        >
          Login
        </Button>
      </>
    </div>
  );
};

export default ResetPasswordComplete;
