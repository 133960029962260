import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Select,
} from 'antd';
import {
  ICompaniesResponse,
  ICompanyConfigResponse,
  IInvoiceAction,
  IInvoiceById,
} from 'features/invoices/types/invoicesTypes';
import React, { useEffect, useState } from 'react';
import '../styles/invoiceCreateForm.scss';
import { useInvoiceCreate } from '../hooks/useInvoiceCreate';
import { CustomLabel } from 'shared/components/customLabel';
import { Button } from 'shared/components/button';
import { Loading } from 'shared/components/loading';
import { GroupedSelectCodesOptions } from 'shared/types/invoices';
import dayjs, { localeData, weekdays } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { MaskedNumberInput } from 'shared/components/maskedNumberInput/ui/MaskedNumberInput';
dayjs.extend(localeData);
dayjs.extend(weekdays);
dayjs.extend(isoWeek);
interface IInvoiceCreateForm {
  companies?: ICompaniesResponse['results'];
  companyConfig: ICompanyConfigResponse | null;
  isLoadingConfig: boolean;
  invoice?: IInvoiceById | null;
  groupedTaxRegimeOptions: GroupedSelectCodesOptions;
  groupedCfdiOptions: GroupedSelectCodesOptions;
  initialCfdiValue: string;
  initialTaxRegimeValue: string;
  hasMore?: boolean;
  isLoadingCompanies?: boolean;
  handleCompanyChange?: (value: number) => void;
  navigateToSavedInvoices: () => void;
  handleCreateInvoice: (data: FormData) => void;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}

const { Option, OptGroup } = Select;
export const InvoiceCreateForm: React.FC<IInvoiceCreateForm> = ({
  companies,
  companyConfig,
  isLoadingConfig,
  invoice,
  groupedTaxRegimeOptions,
  groupedCfdiOptions,
  initialCfdiValue,
  initialTaxRegimeValue,
  hasMore,
  isLoadingCompanies,
  handleCompanyChange,
  navigateToSavedInvoices,
  handleCreateInvoice,
  setPage,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    formFields,
    initialState,
    isHasTaxRegime,
    isHasCfdi,
    deciderActions,
    addFieldsToForm,
  } = useInvoiceCreate({
    companyConfig,
    invoice,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [form] = Form.useForm<FormData>();
  useEffect(() => {
    if (isHasTaxRegime) {
      form.setFieldValue('tax_regime', initialTaxRegimeValue);
    }
    if (isHasCfdi) {
      form.setFieldValue('cfdi', initialCfdiValue);
    }
  }, [isHasTaxRegime, isHasCfdi, form, companyConfig]);

  useEffect(() => {
    handleValidateForm();
  }, [formFields]);
  const isDateField = (value: any): boolean => {
    return value instanceof Date || dayjs.isDayjs(value);
  };

  const formatNumber = (key: string, value: any) => {
    const field = formFields.find((item) => item.field_name[0] === key);
    if (field && field.field_type === 'number') {
      return value.replace(/,/g, '');
    }
    return value;
  };

  const handleSubmit = async (values: FormData) => {
    setLoading(true);
    try {
      const formattedValues = Object.keys(values).reduce(
        (acc, key) => {
          const value: any = values[key as keyof typeof values];
          const validators = [
            (val: any) => (typeof val === 'string' ? val.trim() : val),
            (val: any) =>
              isDateField(val) ? dayjs(val).format('DD-MM-YYYY') : val,
            (val: any) => formatNumber(key, val),
          ];
          const processedValue = validators.reduce(
            (val, validator) => validator(val),
            value
          );

          acc[key as keyof typeof values] = processedValue;
          // acc[key as keyof typeof values] =
          //   typeof value === 'string' ? value.trim() : value;
          // acc[key as keyof typeof values] = isDateField(value)
          //   ? dayjs(value).format('DD-MM-YYYY')
          //   : value;
          // acc[key as keyof typeof values] = formatNumber(key, value);
          return acc;
        },
        {} as typeof values
      );
      await handleCreateInvoice(formattedValues);
    } catch (error) {
      const typedError = error as { data?: { key?: string } };

      if (typedError?.data) {
        const error = Object.values(typedError?.data)[0];
        message.error(error || 'Sorry something went wrong.');
        return;
      }

      message.error('Sorry something went wrong.');
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };

  const handleValidateForm = () => {
    const values = form.getFieldsValue();
    const hasEmptyFields = Object.values(values).some((value) => {
      if (typeof value === 'string') {
        return value.trim() === '';
      }

      if (value === undefined || value === null) {
        return true;
      }
      return false;
    });
    setIsButtonDisabled(hasEmptyFields);
  };

  const handleFormValuesChange = () => {
    handleValidateForm();
  };

  const getFieldsValue = () => {
    const formValues: Record<string, any> = form.getFieldsValue();
    const allValuesFromDeciderParens = deciderActions.map(
      (item) => item.field_name[0]
    );
    const formFieldsWithValue: string[] = allValuesFromDeciderParens.filter(
      (key) => formValues[key]
    );
    const fieldsValues = formFieldsWithValue.map((item) => formValues[item]);
    return fieldsValues;
  };

  const onChangeRadio = (e: RadioChangeEvent, field: IInvoiceAction) => {
    if (field.is_decider) {
      const fieldsValues = getFieldsValue();
      addFieldsToForm(fieldsValues);
    }
    form.setFieldsValue({
      [field.field_name[0]]: e.target.value,
    });
  };

  const onChangeSelect = (value: string, field: IInvoiceAction) => {
    if (field.is_decider) {
      const fieldsValues = getFieldsValue();
      addFieldsToForm(fieldsValues);
    }
    form.setFieldsValue({
      [field.field_name[0]]: value,
    });
  };

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { target } = event;
    if (
      (target as HTMLDivElement).scrollTop +
        (target as HTMLDivElement).clientHeight >=
      (target as HTMLDivElement).scrollHeight
    ) {
      if (hasMore && setPage) {
        setPage((prev) => prev + 1);
      }
    }
  };

  return (
    <div className="invoice-create-form-wrapper">
      <h2 className="invoice-create-form-block-title">Invoice Details </h2>
      {handleCompanyChange && companies?.length && (
        <div className="invoice-create-form-select-company">
          <h3 className="invoice-create-form-select-company-title">
            Select Company
          </h3>
          <Select
            showSearch
            style={{ width: '100%', maxWidth: '600px' }}
            placeholder="Select a company"
            onChange={(value) => {
              handleCompanyChange(value);
              form.resetFields();
            }}
            onPopupScroll={handlePopupScroll}
            filterOption={(input, option) => {
              if (!option || typeof option.children !== 'string') {
                return false;
              }
              const optionChildren = option.children as string;
              return optionChildren.toLowerCase().includes(input.toLowerCase());
            }}
            loading={isLoadingCompanies}
          >
            {companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>
      )}

      {isLoadingConfig && (
        <div className="center-page">
          <Loading size="large" />
        </div>
      )}
      {companyConfig?.id && !isLoadingConfig && (
        <div className="invoice-create-form">
          <h3 className="invoice-create-form-name">
            {companyConfig.name}
            <span>({companyConfig.url || ''})</span>
          </h3>
          {!!formFields?.length && (
            <div className="invoice-create-form-block">
              {initialState && formFields && (
                <Form
                  form={form}
                  layout="vertical"
                  style={{ maxWidth: '600px', width: '100%' }}
                  initialValues={initialState}
                  onFinish={handleSubmit}
                  onValuesChange={handleFormValuesChange}
                  disabled={loading}
                >
                  {formFields.map((field, index) => {
                    if (field.field_type === 'date') {
                      return (
                        <Form.Item
                          key={field.field_name[0] + `${index}`}
                          label={
                            <CustomLabel text={field.verbose_name || ''} />
                          }
                          name={field.field_name[0]}
                          rules={[
                            {
                              required: true,
                              message: `Please input your ${field.verbose_name}!`,
                            },
                          ]}
                          validateTrigger="onSubmit"
                          className="invoice-create-form-item"
                        >
                          <DatePicker
                            className="input"
                            size="large"
                            format="DD-MM-YYYY"
                          />
                        </Form.Item>
                      );
                    } else if (field.field_type === 'select') {
                      return (
                        <Form.Item
                          key={field.field_name[0] + `${index}`}
                          label={
                            <CustomLabel text={field.verbose_name || ''} />
                          }
                          name={field.field_name[0]}
                          rules={[
                            {
                              required: true,
                              message: `Please input your ${field.verbose_name}!`,
                            },
                          ]}
                          className="invoice-create-form-item"
                        >
                          <Select
                            //   key={taxRegimOptions.length + typeUser}
                            showSearch
                            style={{ width: '100%' }}
                            options={field.options || []}
                            size="large"
                            onChange={(value) => {
                              onChangeSelect(value, field);
                            }}
                            filterOption={(input, option) => {
                              if (!option || typeof option.label !== 'string') {
                                return false;
                              }
                              const optionLabel = option.label as string;
                              return optionLabel
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          />
                        </Form.Item>
                      );
                    } else if (field.field_type === 'radio') {
                      return (
                        <Form.Item
                          key={field.field_name[0] + `${index}`}
                          label={
                            <CustomLabel text={field.verbose_name || ''} />
                          }
                          name={field.field_name[0]}
                          rules={[
                            {
                              required: true,
                              message: `Please select an option for ${field.verbose_name}!`,
                            },
                          ]}
                          className="invoice-create-form-item"
                        >
                          <Radio.Group
                            onChange={(e) => onChangeRadio(e, field)}
                          >
                            {field.options.map((option) => (
                              <Radio key={option.value} value={option.value}>
                                {option.label}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    } else if (field.field_type === 'checkbox') {
                      return (
                        <Form.Item
                          key={field.field_name[0] + `${index}`}
                          name={field.field_name[0]}
                          valuePropName="checked"
                          className="invoice-create-form-item"
                        >
                          <Checkbox>{field.verbose_name}</Checkbox>
                        </Form.Item>
                      );
                    } else {
                      return (
                        <React.Fragment key={field.field_name[0] + `${index}`}>
                          <Form.Item
                            label={
                              <CustomLabel text={field.verbose_name || ''} />
                            }
                            name={field.field_name[0]}
                            rules={[
                              {
                                required: true,
                                message: `Please input your ${field.verbose_name}!`,
                              },
                            ]}
                            validateTrigger="onSubmit"
                            className="invoice-create-form-item"
                          >
                            {field.field_type === 'number' ? (
                              <MaskedNumberInput
                                initialValue={`${initialState[field.field_name[0]] || 0}`}
                              />
                            ) : (
                              <Input
                                type="text"
                                maxLength={512}
                                className="input"
                                size="large"
                              />
                            )}
                          </Form.Item>
                        </React.Fragment>
                      );
                    }
                  })}
                  {(isHasTaxRegime || isHasCfdi) && (
                    <div>
                      {handleCompanyChange && (
                        <h3 className="mb-16 mt-16">
                          (Optional) Change Tax Regime or/and CFDI
                        </h3>
                      )}

                      {isHasTaxRegime && (
                        <Form.Item
                          key="tax_regime_select"
                          label={<CustomLabel text={'Tax Regime'} />}
                          name={'tax_regime'}
                          rules={[]}
                          className="invoice-create-form-item"
                        >
                          <Select style={{ width: '100%' }} size="large">
                            <OptGroup label={<CustomLabel text="Personal" />}>
                              {groupedTaxRegimeOptions.personal.map(
                                (option) => (
                                  <Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Option>
                                )
                              )}
                            </OptGroup>
                            <OptGroup label={<CustomLabel text="Company" />}>
                              {groupedTaxRegimeOptions.company.map((option) => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </OptGroup>
                          </Select>
                        </Form.Item>
                      )}
                      {isHasCfdi && (
                        <Form.Item
                          key="cfdi_select"
                          label={<CustomLabel text={'CFDI'} />}
                          name={'cfdi'}
                          rules={[]}
                          className="invoice-create-form-item"
                        >
                          <Select style={{ width: '100%' }} size="large">
                            <OptGroup label={<CustomLabel text="Personal" />}>
                              {groupedCfdiOptions.personal.map((option) => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </OptGroup>
                            <OptGroup label={<CustomLabel text="Company" />}>
                              {groupedCfdiOptions.company.map((option) => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </OptGroup>
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  )}

                  <div className="invoice-create-form-buttons">
                    <Button
                      type="button"
                      className="flex-row mb-24"
                      onClick={navigateToSavedInvoices}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="flex-row mb-24"
                      disabled={isButtonDisabled || loading}
                    >
                      Save Invoice
                      {loading && (
                        <Loading className="ml-12 spin-white" size="small" />
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
