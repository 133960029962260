import React from 'react';
import statusInvoiceImg from '../../../shared/assets/images/status_invoice.png';
import downloadFacturaImg from '../../../shared/assets/images/download_factura.png';
import { ISteps } from '../types/onboardingTypes';
import { HandIcon } from 'shared/assets/icons';
export const Step3: React.FC<ISteps> = ({ setNextStep, loading }) => {
  return (
    <div className="onboarding-step">
      <div className="img img-status">
        <img src={statusInvoiceImg} alt="status-img" />
        <div className="description description-status">
          <HandIcon className="icon-hand" />
          Monitor the status of all your invoices for different vendors from the
          ‘Submitted Invoices’ page
        </div>
      </div>

      <div className="img img-download">
        <img src={downloadFacturaImg} alt="download-factura-img" />
        <div className="description description-download">
          <HandIcon className="icon-hand" />
          Easy download of vendor’s Factura
        </div>
      </div>
    </div>
  );
};
