import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/button';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import '../styles/onboarding.scss';
import { Stages } from 'shared/components/stages';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import {
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
} from 'features/profile/api';
import { useAppDispatch, useAppSelector } from 'app/providers/store';
import { profileSlice } from 'features/profile/api/profileSlice';
import { message } from 'antd';
import { Loading } from 'shared/components/loading';
const Onboarding = () => {
  const { profile } = useAppSelector((state) => state.profileReducer);
  const [fetchProfile] = useLazyGetProfileQuery();

  const [updateProfile] = useUpdateProfileMutation();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { setProfile } = profileSlice.actions;

  const [activeStage, setActiveStage] = useState(0);
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(RoutePath['/invoices/saved']);
  };

  const setNextStep = () => {
    if (activeStage !== 2) {
      setActiveStage((prev) => prev + 1);
    } else {
      handleFinishOnboarding();
    }
  };

  const handleComplete = () => {
    handleFinishOnboarding();
  };

  const handleFinishOnboarding = async () => {
    try {
      setLoading(true);
      if (!profile?.user.id) return;

      await updateProfile({
        data: { is_onboarding_passed: true },
        userId: profile.user.id,
      }).unwrap();
      const profileData = await fetchProfile({}).unwrap();
      if (profileData) {
        dispatch(setProfile(profileData));
      }

      navigate(RoutePath['/invoices/saved']);
    } catch (error) {
      message.error('something vent wrong');
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = () => {
    switch (activeStage) {
      case 0:
        return 'Create Your Profile Once And You Never Have To Duplicate Your Personal Details';
      case 1:
        return 'Create, Manage, and Track Your Invoices with a Couple of Clicks';
      case 2:
        return 'Download Your Factura From The Processed Invoices';
    }
  };

  return (
    <div className="onboarding">
      <h2 className="mb-24">{renderTitle()}</h2>
      <Button
        className="btn-onboarding-next flex-row"
        onClick={setNextStep}
        disabled={loading}
      >
        {activeStage !== 2 ? 'Next' : 'Go to App'}
        {activeStage === 2 && loading && (
          <Loading className="ml-12 spin-white " size="small" />
        )}
      </Button>
      <Stages
        activeStage={activeStage}
        stages={[
          <>
            <Step1 setNextStep={setNextStep} />
          </>,
          <>
            <Step2 setNextStep={setNextStep} />
          </>,
          <>
            <Step3 setNextStep={handleComplete} loading={loading} />
          </>,
        ]}
      />

      {activeStage !== 2 && (
        <Button
          className="btn-onboarding-skip"
          onClick={handleComplete}
          disabled={loading}
        >
          Skip
        </Button>
      )}
    </div>
  );
};

export default Onboarding;
