import React from 'react';
import { CustomLink } from 'shared/components/customLink';
import { ErrorMessage } from 'shared/components/errorMessage';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';

interface Props {
  text?: string;
}

export const ErrorVerify: React.FC<Props> = ({ text }) => {
  return (
    <>
      <span className="text-size-80">🤔</span>
      <ErrorMessage className="normal-16 text-bold mt-16 mb-16">
        {text || 'Oops... Something went wrong. Try again'}
      </ErrorMessage>
      <CustomLink to={RoutePath['/login']}>Login</CustomLink>
    </>
  );
};
